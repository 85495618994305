<template>
  <v-form ref="form">
    <v-text-field
      v-model="umUser.name"
      label="Name"
      required
      :rules="[v => !!v || 'Name is required']"
    ></v-text-field>

    <v-autocomplete
      :items="countries"
      item-text="title"
      item-value="code"
      v-model="umUser.countryCode"
      label="Country"
      required
      :rules="[v => !!v || 'Country is required']"
    ></v-autocomplete>

    <v-text-field
      :prefix="umUser.countryCode"
      v-model="umUser.mobileNumber"
      label="Mobile Number"
      required
      :rules="mobileNumberRules"
    ></v-text-field>

    <v-text-field v-model="umUser.email" label="E-mail" required :rules="emailRules"></v-text-field>

    <v-text-field
      v-if="type != 'UPDATE'"
      v-model="umUser.password"
      :append-icon="showPassword ? 'visibility' : 'visibility_off'"
      :type="showPassword ? 'text' : 'password'"
      label="Password"
      :rules="passwordRules"
      required
      @click:append="showPassword = !showPassword"
    ></v-text-field>

    <v-autocomplete
      :items="organization"
      item-text="name"
      item-value="_id"
      v-model="dbUser.organizationId"
      label="Organization"
    ></v-autocomplete>

    <v-select
      v-model="dbUser.roles"
      :items="roles"
      label="User Roles"
      multiple
      required
      :rules="[v => v.length > 0 || 'User Role is required']"
    ></v-select>

    <v-btn class="mr-5" color="primary" @click="$router.push({ name: 'DashboardUsers' })">Back</v-btn>
    <v-btn class="mr-5" color="primary" @click="updateUser()" v-if="type == 'UPDATE'">Update</v-btn>
    <v-btn class="mr-5" color="primary" @click="createUser" v-if="type == 'CREATE'">Create</v-btn>
  </v-form>
</template>

<script>
import RestResource from "../../services/dataServiceCD.js";
import CommonRestResouce from "../../services/dataServiceAdmin.js";
const service = new RestResource();
const commonService = new CommonRestResouce();

export default {
  name: "CreateUser",
  props: {
    umUser: Object,
    dbUser: Object,
    type: String
  },
  data() {
    return {
      organization: [],
      countries: [],
      showPassword: false,
      roles: ["USER", "ADMIN"],
      emailRules: [
        v => !!v || "E-mail is required",
        v => /.+@.+\..+/.test(v) || "E-mail must be valid"
      ],
      mobileNumberRules: [value => !!value || "Mobile number required."],
      passwordRules: [
        value => !!value || "Password required.",
        value => (value || "").length >= 8 || `Min 8 characters`
      ]
    };
  },
  mounted() {
    this.loadOrganization();
    this.fetchCountries();
  },
  methods: {
    createUser() {
      if (this.$refs.form.validate()) {
        this.$setLoader();
        service.createUser({ ...this.umUser, ...this.dbUser, created_by: this.$store.state.userProfile.name }).then(r => {
          if (r != undefined) {
            alert("Successfully created user");
            this.$router.push({ name: "DashboardUsers" });
          }
          
          this.$disableLoader();
        })
      }
    },
  

    async updateUser() {
      if (this.$refs.form.validate()) {
        this.updateDBAndUMUser(this.dbUser, this.umUser);
      }
    },

    updateDBAndUMUser(dbUser, umUser) {
      dbUser.updated_at = Date.now();
      this.$setLoader();
      service
        .updateUser(this.dbUser)
        .then(() => {
          umUser.updated_at = Date.now();
          commonService
            .updateUMUser(umUser)
            .then(() => {
              this.$disableLoader();
              alert("Successfully updated user");
            })
            .catch(() => {
              this.$disableLoader();
              alert("Failed to update user");
            });
        })
        .catch(() => {
          this.$disableLoader();
          alert("Failed to update user");
        });
    },

    fetchCountries() {
      service.getConfig("countries").then(r => {
        this.countries = r.data;
      });
    },

    loadOrganization() {
      commonService.fetchOrganizations().then(r => {
        this.organization = r.data.organizations;
      });
    }
  }
};
</script>
