import axios from 'axios'
import Util from "../../Util.js"

var ADMIN_BACKEND_URL = process.env.VUE_APP_ADMIN_BACKEND_URL

export default class RestResource {
    async getConfig(key) {
        const url = `${ADMIN_BACKEND_URL}/common/data/getConfig?key=${key}`
        return axios.get(url, Util.setupHeaders())
    }

    async fetchUsers(data) {
        const url = `${ADMIN_BACKEND_URL}/customer/fetchUsers`
        return axios.post(url, data, Util.setupHeaders())
    }

    async createUser(profile) {
        const url = `${ADMIN_BACKEND_URL}/customer/createUser`
        return axios.post(url, profile, Util.setupHeaders())
    }

    async updateUser(user) {
        const url = `${ADMIN_BACKEND_URL}/customer/updateUser`
        return axios.post(url, user, Util.setupHeaders())
    }
}