<template>
  <v-card>
    <v-card-title>
      Create User
    </v-card-title>
    <v-container>
      <UserForm
        :dbUser="dbUser"
        :umUser="umUser"
        type="CREATE"
      />
    </v-container>
  </v-card>
</template>

<script>
import UserForm from "./form.vue"

export default {
  components: {
    UserForm
  },
  data() {
    return {
      dbUser: {},
      umUser: {},
    };
  }
};
</script>
